<template>
  <div class="">
    <el-dialog title="设置选项"
               :close-on-click-modal="false"
               :visible.sync="dialogVisible"
               width="50%">
      <el-form :model="form"
               :rules="rules"
               label-position="top"
               ref="form">
        <el-form-item label="库存"
                      prop="quantity">
          <el-input type="text"
                    placeholder="库存"
                    v-model="form.quantity"></el-input>
        </el-form-item>
        <el-form-item class="tr">
          <el-button type="primary"
                     class="mt-15"
                     @click="submitForm('form')">立即{{form.id!==''?'修改':'创建'}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { setMemberAppointmentNet } from '@/api/market'
export default {
  props: ['aid'],
  data () {
    return {
      dialogVisible: false,
      imgList: [],
      form: {
        id: '',
        quantity: 0
      },
      rules: {
        quantity: [
          { required: true, message: '请输入库存', trigger: 'blur' }
        ]
      }
    }
  },
  components: {
  },
  methods: {
    showDialog (scoped) {
      this.dialogVisible = true
      if (scoped.row) {
        const formData = JSON.parse(JSON.stringify(scoped.row))
        this.form = formData
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const formData = JSON.parse(JSON.stringify(this.form))
          const res = await setMemberAppointmentNet({
            activity_id: this.aid, ...formData
          })
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '新增'}成功`,
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
