<template>
  <div class="container">
    <table-page dataName="list"
                ref="table"
                :search="search"
                :params="{activity_id:$route.query.id}"
                @on-success="fetchSuccess"
                :request="getMemberAppointmentNets">
      <template slot="button">
        <FileUpload type="primary"
                    @on-success="$refs.table.fetch()"
                    buttonType="icon"
                    :params="{import_type:'appointment_gift_network_data',carry_data:{activity_id:$route.query.id}}"
                    v-permission="['ADMIN']">导入网点</FileUpload>
      </template>
      <el-table-column prop="area_id"
                       show-overflow-tooltip
                       label="网点ID">
      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="网点名称">
        <template slot-scope="scope">
          {{`${scope.row.province || ''}${scope.row.city || ''}${scope.row.district || ''}${scope.row.net || ''}`}}
        </template>
      </el-table-column>
      <el-table-column prop="options_name"
                       show-overflow-tooltip
                       label="选项名">
      </el-table-column>
      <el-table-column prop="quantity"
                       show-overflow-tooltip
                       label="库存">
      </el-table-column>
      <el-table-column label="操作"
                       v-permission="['ADMIN']">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="updateNet(scope.row.id)">更新网点信息</el-button>
          <el-button type="text"
                     @click="setOption(scope)">编辑</el-button>
          <el-button type="text"
                     @click="delNetOption(scope.row)"
                     v-permission="['ADMIN']">删除</el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetOption ref="setOption"
               :aid="$route.query.id"
               @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import FileUpload from '@/components/FileUploadAsync'
import SetOption from './components/SetOption'
import { getMemberAppointmentNets, delMemberAppointmentNet, memberAppointmentupdateNetwork } from '@/api/market'
export default {
  name: 'MemberAppointmentNet',
  data () {
    return {
      getMemberAppointmentNets,
      qrcodeUrl: '',
      disabled: {},
      area: {},
      exportUrl: '',
      activity_id: '',
      search: [{ type: 'area' }]
    }
  },
  components: {
    TablePage,
    FileUpload,
    SetOption
  },
  methods: {
    setOption (scope) {
      this.$refs.setOption.showDialog(scope)
    },
    showQrcode (row) {
      this.qrcodeUrl = row.web_url
      this.$refs.qrcode.getQRCode()
    },
    delNetOption (row) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delMemberAppointmentNet({
          activity_id: this.$route.query.id,
          id: row.id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      }).catch(() => { })
    },
    exportStoresData () {
      window.open(this.exportUrl, '_blank')
    },
    fetchSuccess (e) {
      this.exportUrl = e.customer_export_url
    },
    async updateNet (id) {
      this.$confirm('确认更新', {
        type: 'warning'
      }).then(async () => {
        const res = await memberAppointmentupdateNetwork({
          activity_id: this.$route.query.id,
          id: id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '更新成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        } else {
          this.$message.error(res.meta.msg)
        }
      }).catch(() => { })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
